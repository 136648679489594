// palette
$white: #fff;
$off-white: #f8f8f8;
$off-black: #535b67;
$disabled: #535b6740;
$solid-accent: #599cff;
$gradient-accent: linear-gradient(180deg, #8696d4 0%, #6274c5 100%);
// $gradient-home: linear-gradient(180deg, #8bbcf6 0%, #5468bd 100%);
$gradient-home: linear-gradient(180deg, #8bbcf6 0%, #5468bd 100%);

// spacing
$spacing-0: 0;
$spacing-1: 2px;
$spacing-2: 4px;
$spacing-3: 8px;
$spacing-4: 12px;
$spacing-5: 16px;
$spacing-6: 24px;
$spacing-7: 32px;
$spacing-8: 48px;
$spacing-9: 64px;
$spacing-10: 96px;
$spacing-11: 128px;
$spacing-12: 256px;

// font-size
$font-size-1: 14px;
$font-size-2: 16px;
$font-size-3: 24px;
$font-size-4: 48px;
$font-size-5: 64px;
$font-size-6: 80px;
$font-size-7: 120px;

// letter-spacing
$letter-spacing-0: 0rem;
$letter-spacing-1: -0.03rem;

// line-height
$line-height-1: 80%;
$line-height-2: 130%;
