// Karla has a variable font
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600&display=swap');
@import './variables';

/**
 * Importing Faubourg, Cormorant, and Karla
*/

@font-face {
  font-family: 'Faubourg';
  src: url('../assets/fonts/Faubourg-Display.woff2') format('woff2'),
    url('../assets/fonts/Faubourg-Display.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant';
  src: url('../assets/fonts/Cormorant-Regular.woff2') format('woff2'),
    url('../assets/fonts/Cormorant-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cormorant';
  src: url('../assets/fonts/Cormorant-Medium.woff2') format('woff2'),
    url('../assets/fonts/Cormorant-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

/**
 * Typography
*/
h1,
.h1 {
  font-family: 'Faubourg';
  font-size: $font-size-5;
  // font-size: 70vw;
  font-weight: 400;
  letter-spacing: $letter-spacing-1;
  line-height: $line-height-1;
}

h2,
.h2 {
  font-family: 'Faubourg';
  font-size: $font-size-4;
  font-weight: 400;
  letter-spacing: $letter-spacing-1;
  line-height: $line-height-1;
}

h3,
.h3 {
  font-family: 'Cormorant';
  font-size: $font-size-4;
  font-weight: 400;
  letter-spacing: $letter-spacing-1;
  line-height: $line-height-1;
}

h4,
.h4 {
  font-family: 'Cormorant';
  font-size: $font-size-3;
  font-weight: 500;
  letter-spacing: $letter-spacing-1;
  line-height: $line-height-1;
}

h5,
.h5 {
  font-family: 'Karla';
  font-size: $font-size-1;
  font-variation-settings: 'wght' 500;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-2;
  padding: 0;
  margin: 0;
}

p,
.body {
  font-family: 'Karla';
  font-size: $font-size-1;
  font-weight: 400;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-2;
}

// convert to desktop type styles

@media only screen and (min-width: 1000px) {
  h1,
  .h1 {
    font-size: $font-size-7;
  }

  h1,
  .h1 {
    font-size: $font-size-7;
  }

  h2,
  .h2 {
    font-size: $font-size-6;
  }

  h3,
  .h3 {
    font-size: $font-size-5;
  }

  h4,
  .h4 {
    font-size: $font-size-3;
  }

  h5,
  .h5 {
    font-size: $font-size-1;
  }

  p,
  .body {
    font-size: $font-size-2;
  }
}
