@import '../../styles/variables.scss';

.card {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 100;

  &-main {
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  &-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: $spacing-8;
    padding-bottom: 1em;
    max-width: 90%;
  }

  &-heading {
    text-align: center;
    text-transform: uppercase;
    font-family: Faubourg;
    line-height: $line-height-1;
    color: $white;
    margin-top: 100px;
    margin-bottom: 0px;
    z-index: 101;
  }

  &-designers {
    font-family: Cormorant;
    text-transform: capitalize;
    margin: 0 auto;
    font-size: $font-size-3;
  }

  &-description,
  .subline-description {
    max-width: 700px;
    margin: $spacing-4 auto $spacing-8 auto;
    font-size: $font-size-1;
    line-height: 130%;
  }

  &-images {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin-top: $spacing-4;

    img {
      flex-basis: 1em;
      margin: 0.15em 0;
      border: 1px solid $off-white;
      border-radius: 8px;
      width: 100%;
    }
  }
}

.subline-name {
  font-family: Faubourg;
  font-size: $font-size-4;
}

.subline-designers {
  font-family: Cormorant;
  text-transform: capitalize;
  font-size: $font-size-3;
  margin-bottom: $spacing-4;
}

@media only screen and (min-width: 1200px) {
  .card {
    &-images {
      flex-direction: row;

      img {
        margin: 0 0.15em;
        height: 27vw;
        width: auto;
      }
    }
  }
}
