@import '../../styles/variables.scss';

.container {
    display: flex;
}

.box {
    width: 100px !important;
    height: 100px  !important;
    border: 1px solid $off-white;
    
    &:not(:last-of-type) {
        margin-right: 16px;
    }

    &--white {
        background-color: $white;
    }

    &--off-white {
        background-color: $off-white;
    }

    &--off-black {
        background-color: $off-black;
    }

    &--disabled {
        background-color: $disabled;
    }

    &--gradient-accent {
        background: $gradient-accent;
    }
    
    &--solid-accent {
        background-color: $solid-accent;
    }
}
