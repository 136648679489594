@import '../../styles/variables';

.btn {
  display: inline-block;
  color: $solid-accent;
  padding: 6px 16px;
  border: 1px solid $solid-accent;
  border-radius: $spacing-7;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    transform: scale(1.05);
    background: $solid-accent;
    color: $off-white;

    a {
      color: $off-white;
    }
  }

  a {
    text-decoration: none;
    color: $solid-accent;
  }
}

.btnWhite {
  background: transparent;
  border: 1px solid $white;
  color: $white;

  &:hover {
    background: $gradient-accent;
    opacity: 0.8;
  }

  a {
    color: inherit;
  }
}

@media (pointer: coarse) {
  .btn:hover {
    transform: none;
    background: transparent;
    color: $off-white;
    opacity: 1;
    color: $solid-accent;
  }

  .btnWhite:hover {
    background: transparent;
    color: $off-white;
    opacity: 1;
    // background: yellow;
  }
}
