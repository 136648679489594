@import '../../styles/variables.scss';

.container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 4rem);
  width: calc(100vw - 4rem);
  padding: 24px;
  background-color: $white;
  z-index: 3;
}

.wrapper {
  display: inline-block;
}

.logo {
  transition: opacity 0.2s;
}
.logo:hover {
  opacity: 0.7;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5rem;
}

.item,
.disabled,
.highlight {
  display: inline-flex;
  cursor: pointer;
  text-decoration: none;

  margin: 0;
  transition: opacity 0.1s, transform 0.3s;
}

.item:hover {
  transform: translateX(2px);
}

.active {
  color: $off-black;
}

.active:hover {
  color: $solid-accent;
}

.disabled {
  color: $disabled;
  cursor: not-allowed;
}

.disabled:hover {
  opacity: 1 !important;
}

.highlight {
  color: $solid-accent;
}

.highlight:hover {
  opacity: 0.9 !important;
}

.item:not(:last-child) {
  padding-bottom: 0.25rem;
}

.active {
  color: $off-black;
}

.item a {
  text-decoration: none;
  color: inherit;
}

// .callout {
//   font-size: 0.875rem;
//   font-family: 'Karla';
//   font-weight: 500;
//   line-height: 130%;
//   letter-spacing: 0px;
// }

.noiseLayer {
  z-index: 3;
  pointer-events: none;
}
