@import '../../styles/variables.scss';

.homeContainer {
  position: static;
}

.home {
  position: relative;
  overflow: hidden;
  background: $off-white;
  width: 100vw;
  height: 100vh;

  .LG-logo-container {
    position: absolute;
    color: white;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
  }

  &-main {
    width: calc(100vw);
    height: calc(100vh);
    background: $gradient-home;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 2;
    pointer-events: none;
  }

  &-logo {
    position: absolute;
    left: 50%;
    top: 40%;
    width: 80%;

    transform: translate(-50%, -50%);

    h5 {
      margin-top: 20px;
      text-align: center;
      color: $off-white;
      text-transform: uppercase;
      opacity: 0;
      transition: opacity 1.5s;
    }

    img {
      max-width: 100%;

      // width: 20%;
      // height: auto;
      margin: 0 auto;
      display: flex;
      // width: 80%;
      justify-self: center;
    }
  }

  .showSubheader {
    opacity: 1 !important;
  }

  &-flowers {
    position: absolute;
    left: 50%;
    bottom: -60vw;
    width: 150%;
    height: auto;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  &-nav-left {
    position: absolute;

    top: 2vh;
    left: 2vw;
  }

  &-nav-right {
    position: absolute;

    top: 2vh;
    right: 2vw;
  }
}

.progress {
  position: absolute;
  left: 38%;
  right: 38%;
  bottom: calc(40% - 16px);
  height: 4px;
  background-color: #99daff;
  border-radius: $spacing-4;

  &-bar {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $solid-accent;
    opacity: 1;
  }
}

// convert to desktop type styles
@media only screen and (min-width: 768px) {
  .LG-logo-container {
    top: 6vh !important;
  }

  .home {
    &-logo {
      width: 100%;
      max-width: 450px;
    }

    &-flowers {
      width: 125%;
    }

    &-nav-left {
      position: absolute;
      top: 2vh;
      left: 2vw;
    }

    &-nav-right {
      position: absolute;
      top: 2vh;
      right: 2vw;
    }
  }
}

// convert to desktop type styles
@media only screen and (min-width: 1000px) {
  .home {
    &-logo {
      width: 100%;
    }

    &-flowers {
      width: 125%;
      bottom: -50vw;
    }

    &-nav-left {
      top: 4vh;
      left: 2vw;
    }

    &-nav-right {
      top: 4vh;
      right: 2vw;
    }
  }

  .progress {
    left: 44%;
    right: 44%;
  }
}
