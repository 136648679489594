@import '../../styles/variables.scss';

.noise {
  z-index: 1000;
  pointer-events: none;
}

.wrapper {
  display: inline-block;
}

.lines {
  color: $white;
  // z-index: 3;

  padding: 0px;

  &-main {
    position: fixed;

    text-align: center;
    background: $gradient-home;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding: 0;
    opacity: 1;
    display: flex;
    justify-content: center;
  }

  &-list {
    padding-left: 0;
    text-align: center;
    list-style-type: none;
    color: $white;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
  }

  &-item {
    text-transform: uppercase;
    font-family: Faubourg;
    width: auto;
    transition: 0.2s;

    p {
      line-height: $line-height-1;
      font-family: Faubourg;
      margin: 0;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.01);
    }

    &:first-child:hover {
      transform: scale(1);
    }

    &:active {
      transform: scale(1);
    }
  }

  &-item:last-child {
    padding-bottom: 1em;
  }
}

.flowers {
  z-index: 200;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 1;
  overflow: hidden;

  &-right,
  &-left {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    > * {
      position: fixed;
    }
  }

  &-right {
    &-blue {
      bottom: -70px;
      right: -100px;
      width: 300px;
    }

    &-dragonfly {
      transform: rotate(-35deg);
      bottom: 550px;
      right: 90px;
    }
  }

  &-left {
    &-blue {
      width: 300px;
      transform: rotate(25deg);
      left: -120px;
      bottom: -105px;
    }

    &-red__big {
      transform: rotate(30deg);
      left: -180px;
      bottom: 500px;
    }

    &-red__small {
      transform: rotate(45deg);
      bottom: 550px;
      left: -75px;
    }
  }

  &-left-red__big,
  &-left-red__small,
  &-right-dragonfly {
    display: none;
  }
}

.lines-item p,
.lines-list,
.card-heading {
  font-size: 45px;
}

.flowers {
  display: none;
}

@media only screen and (min-width: 375px) {
  .lines-item p,
  .lines-list,
  .card-heading {
    font-size: 55px;
  }
  .flowers {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .lines-item p,
  .card-heading,
  .lines-list {
    font-size: 7.5vw;
  }

  .flowers-left-red__big,
  .flowers-left-red__small,
  .flowers-right-dragonfly {
    display: block;
  }

  .flowers {
    &-left {
      &-blue {
        width: 380px;
      }
      &-red__big {
        width: 275px;
        left: -140px;
        bottom: 300px;
      }
      &-red__small {
        width: 190px;
        bottom: 350px;
      }
    }
  }

  .flowers {
    &-right {
      &-blue {
        right: -80px;
        width: 350px;
      }
      &-dragonfly {
        width: 200px;
        right: 30px;
      }
    }
  }
}

// convert to desktop type styles
@media only screen and (min-width: 1200px) {
  .lines {
    &-nav-left {
      top: 2rem;
      left: 2rem;
    }

    &-nav-right {
      top: 2rem;
      right: 2rem;
    }
  }

  .flowers {
    &-right {
      &-blue {
        bottom: -100px;
        right: -80px;
        width: 450px;
      }

      &-dragonfly {
        width: 250px;
        bottom: 600px;
        right: 10px;
      }
    }

    &-left {
      &-blue {
        width: 500px;
        left: -180px;
      }

      &-red__big {
        width: 325px;
        left: -160px;
        bottom: 350px;
      }

      &-red__small {
        width: 225px;
        bottom: 405px;
        left: -65px;
      }
    }
  }
}
